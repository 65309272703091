html,
body {
  /* Reset the document's margin values */
  margin: 0;
  /* Reset the document's padding values */
  padding: 0;
  /* Use the platform's native font as the default */
  font-family: Arial, 'Roboto', -apple-system, 'San Francisco', 'Segoe UI', 'Helvetica Neue', sans-serif;
  /* Define a reasonable base font size */
  font-size: 10pt;
  /* Styles for better appearance on screens only -- are reset to defaults in print styles later */
  /* Use a non-white background color to make the content areas stick out from the full page box */
  background-color: #eee;
}

/* Styles that are shared by all elements */

* {
  /* Include the content box as well as padding and border for precise definitions */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page {
  /* Styles for better appearance on screens only -- are reset to defaults in print styles later */
  /* Divide single pages with some space and center all pages horizontally */
  margin: 1cm auto;
  /* Define a white paper background that sticks out from the darker overall background */
  background: #fff;
  /* Show a drop shadow beneath each page */
  box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
  /* Override outline from user agent stylesheets */
  outline: 0;
  width: 21cm;
  /* Reflect the paper height in the screen rendering (must match size from @@page rule) */
  min-height: 29.7cm;
  /* Reflect the actual page margin/padding on paper in the screen rendering (must match margin from @@page rule) */
  padding-left: 2cm;
  padding-top: 0.2cm;
  padding-right: 2cm;
  padding-bottom: 0.2cm;
}

/* Defines a class for manual page breaks via inserted .page-break element */

div.page-break {
  page-break-after: always;
}

/* For top-level headings only */

h1 {
  /* Force page breaks after */
  page-break-before: always;
}

/* For all headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  /* Avoid page breaks immediately */
  page-break-after: avoid;
}

/* For all paragraph tags */

p {
  /* Reset the margin so that the text starts and ends at the expected marks */
  margin: 0;
}

/* For adjacent paragraph tags */

p + p {
  /* Restore the spacing between the paragraphs */
  margin-top: 0.5cm;
}

/* For links in the document */

a {
  /* Prevent colorization or decoration */
  text-decoration: none;
  color: black;
}

/* For tables in the document */

table {
  /* Avoid page breaks inside */
  page-break-inside: avoid;
}

/* Use CSS Paged Media to switch from continuous documents to sheet-like documents with separate pages */

@page {
  /* You can only change the size, margins, orphans, widows and page breaks here */
  /* Require that at least this many lines of a paragraph must be left at the bottom of a page */
  orphans: 4;
  /* Require that at least this many lines of a paragraph must be left at the top of a new page */
  widows: 2;
}

/* When the document is actually printed */

@media print {
  html,
  body {
    /* Reset the document's background color */
    background-color: #fff;
  }
  .page {
    /* Reset all page styles that have been for better screen appearance only */
    /* Break cascading by using the !important rule */
    /* These resets are absolute must-haves for the print styles and the specificity may be higher elsewhere */
    -webkit-print-color-adjust: exact;
    width: initial !important;
    min-height: initial !important;
    margin: 0 !important;
    padding: 0 !important;
    border: initial !important;
    border-radius: initial !important;
    background: initial !important;
    box-shadow: initial !important;
    /* Force page breaks after each .page element of the document */
    page-break-after: always;
    color: black !important;
  }
  td.text-center.metricNameCell {
    -webkit-print-color-adjust: exact;
    background-color: #001f5c !important;
    color: white !important;
  }
  td.text-left.metricNameCell {
    -webkit-print-color-adjust: exact;
    background-color: #001f5c !important;
    color: white !important;
  }
  #flowLogo {
    position: relative;
  }

  #flowLogo img {
    position: relative;
    top: 5px !important;
    right: -800px !important;
    max-width: 130px !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* Use CSS Paged Media to switch from continuous documents to sheet-like documents with separate pages */

@page {
  /* You can only change the size, margins, orphans, widows and page breaks here */
  /* Paper size and page orientation */
  size: A4 portrait;
  /* Margin per single side of the page */
  margin-left: 2cm;
  margin-top: 0.2cm;
  margin-right: 2cm;
  margin-bottom: 0.2cm;
}

.table > tbody > tr > td,
.table > thead > tr > th {
  width: auto;
  overflow: hidden;
  word-wrap: break-word;
  padding: 0 4px !important;
  border: 1px solid black;
  vertical-align: middle;
}
.metricNameCell {
  background-color: #001f5c;
  color: white;
}

.text-small {
  font-size: 9pt;
}

.text-header {
  font-size: 11pt;
  padding: 0, 6px;
  font-weight: bold;
  color: #073763;
}
.text-title {
  margin-top: 1cm;
  font-size: 15pt;
  font-weight: bold;
  color: #073763;
}

.text-medium {
  margin-top: 0.5cm;
  font-size: 13pt;
  color: #073763;
}
.text-medium-bold {
  font-size: 13pt;
  color: #073763;
  font-weight: bold;
}

.text-medium-cursive {
  font-size: 13pt;
  color: #073763;
  font-style: oblique;
}

.text-important {
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
  font-size: 20pt;
  font-weight: bold;
  color: red;
}

#flowLogo {
  position: relative;
}

.report-content {
  position: relative;
  width: '100%';
  min-height: 29cm;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

#flowLogo img {
  top: 5px;
  right: -560px;
  max-width: 130px;
}

footer {
  font-size: 9px;
  text-align: center;
  bottom: 0;
}
