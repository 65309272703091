@media print {
  h3.text-center.productTitle {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
  }
}

h3.text-center.productTitle {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
