.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.print-only {
    display: none;
}


@media print {
    .no-print {
        display: none;
    }

    .print-only {
        display: block;
    }

    .page {
        /* Reset all page styles that have been for better screen appearance only */
        /* Break cascading by using the !important rule */
        /* These resets are absolute must-haves for the print styles and the specificity may be higher elsewhere */
        width: initial !important;
        min-height: initial !important;
        margin: 0 !important;
        padding: 0 !important;
        border: initial !important;
        border-radius: initial !important;
        background: initial !important;
        box-shadow: initial !important;
        /* Force page breaks after each .page element of the document */
        page-break-after: always;
    }
}

