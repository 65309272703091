/* When the document is actually printed */

@media print {
  img.img-responsive.gridProductImg {
    max-height: 100px;
    height: 100px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

img.img-responsive.gridProductImg {
  max-height: 100px;
  height: 100px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
