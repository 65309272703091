// Here you can add other styles
.top-buffer-sm {
  margin-top: 0.5rem;
}

.top-buffer {
  margin-top: 1rem;
}

.bottom-buffer {
  margin-bottom: 1rem;
}

.left-buffer {
  margin-left: 1rem;
}

.left-buffer-sm {
  margin-left: 0.5rem;
}

.right-buffer {
  margin-right: 1rem;
}

.buffer {
  margin: 1rem;
}

.sticky-header-table {
  position: relative;
}

// This is for when table is inside regular body
.app-body .sticky-header-table thead th {
  top: 55px; // For the fixed header on top
  z-index: 2;
  position: sticky;
}

// This is for when table is inside modal
.MuiDialog-container .sticky-header-table thead th {
  top: 0;
  z-index: 2;
  position: sticky;
}

.sticky-header-table tr {
  z-index: 1;
}

.app-body {
  overflow-x: visible;
}

.MuiCard-root {
  overflow: visible !important;
}

.MuiDialogContent-root {
  padding-top: 0 !important;
}

.sticky-timeline-header {
  position: sticky;
  top: 55px;
  z-index: 1100;
}

.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
}

.fabChat {
  margin: 0px;
  top: auto;
  right: auto;
  bottom: 20px;
  left: 95%;
  position: fixed;
}
