/* When the document is actually printed */

@media print {
  .table-bordered > thead > tr > th,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > td {
    border: 1px solid black !important;
  }
  .table > tbody > tr > td,
  .table > thead > tr > th,
  .table > tr > th {
    width: auto;
    overflow: hidden;
    word-wrap: break-word;
    border: 1px solid black !important;
    vertical-align: middle;
  }

  td.text-center.importantMetric {
    font-weight: bold;
    border: 3px solid #001f5c;
  }
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid black;
}

.table > tbody > tr > td,
.table > thead > tr > th,
.table > tr > th {
  width: auto;
  overflow: hidden;
  word-wrap: break-word;
  border: 1px solid black;
  vertical-align: middle;
}

td.text-center.bold {
  font-weight: bold;
}

td.text-center.bordered {
  border: 3px solid #001f5c;
}
