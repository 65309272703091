/* When the document is actually printed */

@media print {
  img.img-responsive.productImg {
    max-height: 250px;
    min-height: 250px;
    height: 250px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
img.img-responsive.productImg {
  max-height: 250px;
  min-height: 250px;
  height: 250px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
