/* When the document is actually printed */

@media print {
  img.img-responsive.shelfImage {
    display: block;
    max-height: 390px;
    max-width: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

img.img-responsive.shelfImage {
  display: block;
  max-height: 390px;
  max-width: 100%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
